import React from 'react';
import { Flex } from '@aws-amplify/ui-react';
// import { ReactComponent as Placeholder } from 'assets/images/Placeholder.svg';
import { ButtonPrimary } from '@/components/Button';
import { BackgroundHero } from '@/components/BackgroundHero/BackgroundHero';
import PageLayout from '@/components/PageLayout/PageLayout';
import { H1 } from '@/components/Typography/Typography';

const Page404 = () => {
  return (
    <PageLayout paddingTop={240}>
      <BackgroundHero />
      <Flex direction="column" alignItems="center" gap={0} justifyContent="center">
        {/* <Placeholder width="292px" /> */}
        <H1 style={{ fontFamily: 'alphasans-ExtraBold' }} margin="24px 0px">
          Page not found
        </H1>
        <a style={{ all: 'unset' }} href="/">
          <ButtonPrimary>Back to home page</ButtonPrimary>
        </a>
      </Flex>
    </PageLayout>
  );
};

export default Page404;
